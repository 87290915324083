import api from "./api";

export const getCustomerList = async () => {
  const response = await api.get("/customers");
  return response.data;
};

export const getACustomerList = async (email) => {
  const response = await api.get(`/customer?email=${email}`);
  return response.data;
};

export const createCustomerList = async (data) => {
  const response = await api.post("/admin/manage/customers/register", data);
  return response.data;
};

export const registerCustomer = async (data) => {
  const response = await api.post("/customer/register", data);
  return response.data;
};

export const linkDeviceToCustomer = async (data) => {
  const response = await api.post("/admin/manage/device/add", data);
  return response.data;
};

export const editCustomerList = async (data) => {
  const response = await api.patch("/admin/manage/customers/edit", data);
  return response.data;
};

export const deleteCustomerList = async (data) => {
  const response = await api.delete("/admin/manage/customers/delete", { data });
  return response.data;
};

export const getDeviceList = async () => {
  const response = await api.get("/devices");
  return response.data;
};

export const createDeviceList = async (data) => {
  const response = await api.post("/admin/manage/device/register", data);
  return response.data;
};

export const editDeviceList = async (data, id) => {
  const response = await api.patch(`/admin/manage/device/edit/${id}`, data);
  return response.data;
};

export const deleteDeviceList = async (data) => {
  const response = await api.delete("/admin/manage/device/delete", { data });
  return response.data;
};

export const getServiceList = async () => {
  const response = await api.get("/service/data");
  return response.data;
};

export const createServices = async (data) => {
  const response = await api.post("/admin/manage/service/add", data);
  return response.data;
};

export const editServiceList = async (data) => {
  const response = await api.patch("/admin/manage/service/edit", data);
  return response.data;
};

export const deleteServiceList = async (data) => {
  const response = await api.delete("/admin/manage/service/delete", { data });
  return response.data;
};

export const enableDevice = async (data) => {
  const response = await api.post("/device/enable_disable", data);
  return response.data;
};

export const enableTask = async (data) => {
  const response = await api.post("/task/enable_disable", data);
  return response.data;
};

export const getAdminTickets = async (
  priority,
  status,
  start_date,
  end_date
) => {
  const params = new URLSearchParams();

  // Add parameters only if they exist
  if (priority) params.append("priority", priority);
  if (status) params.append("status", status);
  if (start_date) params.append("start_date", start_date);
  if (end_date) params.append("end_date", end_date);

  // Construct the query string
  const queryString = params.toString();
  const response = await api.get(`/admin/tickets?${queryString}`);
  return response.data;
};

export const getCustomerDetails = async () => {
  const response = await api.get("/customer");
  return response.data;
};

export const editCustomerDetails = async (data) => {
  const response = await api.patch("customer/edit", data);
  return response.data;
};

export const getCustomerNodes = async (name, type, start_date, end_date) => {
  const params = new URLSearchParams();

  // Add parameters only if they exist
  if (name) params.append("name", name);
  if (type) params.append("type", type);
  if (start_date) params.append("start_date", start_date);
  if (end_date) params.append("end_date", end_date);

  const queryString = params.toString();

  const response = await api.get(`/objects/get?${queryString}`);
  return response.data;
};

export const createCustomerNodes = async (data) => {
  const response = await api.post("/objects/create", data);
  return response.data;
};

export const editCustomerNodes = async (data) => {
  const response = await api.patch("/objects/edit", data);
  return response.data;
};

export const deleteCustomerNodes = async (data) => {
  const response = await api.delete("/objects/delete", { data });
  return response.data;
};

export const getCustomerDevice = async () => {
  const response = await api.get("/customers/devices");
  return response.data;
};

export const createCustomerDevice = async (data) => {
  const response = await api.post("/device/activate", data);
  return response.data;
};

export const editCustomerDevice = async (data) => {
  const response = await api.patch("/devices/edit", data);
  return response.data;
};

export const deleteCustomerDevice = async (data) => {
  const response = await api.delete("/devices/delete", { data });
  return response.data;
};

export const getMonitorList = async (id, name, start_date, end_date) => {
  const params = new URLSearchParams();

  // Add parameters only if they exist
  if (id && id !== "") params.append("task_id", id);
  if (name) params.append("name", name);
  if (start_date) params.append("start_date", start_date);
  if (end_date) params.append("end_date", end_date);

  const queryString = params.toString();

  const response = await api.get(`/monitoring_tasks?${queryString}`);
  return response.data;
};

export const createMonitor = async (data) => {
  const response = await api.post("/monitoring_tasks", data);
  return response.data;
};

export const editMonitoringTask = async (data) => {
  const response = await api.patch("/update_monitoring_task/info", data);
  return response.data;
};

export const deleteMonitor = async (data) => {
  const response = await api.delete("/monitoring_tasks/delete", { data });
  return response.data;
};

export const postAFrequency = async (data) => {
  const response = await api.post("/monitor", data);
  return response.data;
};

export const getConfigureNodes = async (id) => {
  const response = await api.get(`/task_objects?task_id=${id}`);
  return response.data;
};

export const postANodeConfiguration = async (data) => {
  const response = await api.post("/task_objects", data);
  return response.data;
};

export const getMonitoringAlert = async (id) => {
  const response = await api.get(`/monitoring_alert?task_id=${id}`);
  return response.data;
};

export const getConfigureDeviceList = async (id) => {
  const response = await api.get(`/services/devices?task_id=${id}`);
  return response.data;
};

export const postADeviceConfiguration = async (data) => {
  const response = await api.post("/services/devices", data);
  return response.data;
};

export const updateMonitorTaskDescription = async (data) => {
  const response = await api.patch("/update_monitoring_task/description", data);
  return response.data;
};

export const getNotificationList = async (type) => {
  const response = await api.get(`/alerts/configured?alert_type=${type}`);
  return response.data;
};

export const createCustomerNotification = async (data) => {
  const response = await api.post("/alerts/device_service", data);
  return response.data;
};

export const updateCustomerNotification = async (data) => {
  const response = await api.patch("/alerts/device_service", data);
  return response.data;
};

export const deleteCustomerNotification = async (data) => {
  const response = await api.delete("/alerts/delete", { data });
  return response.data;
};

export const postNotification = async (data) => {
  const response = await api.post("/monitoring_alert/add", data);
  return response.data;
};

export const postDailyTask = async (data) => {
  const response = await api.post("/daily_tasks", data);
  return response.data;
};

export const getTaskValues = async (id) => {
  const response = await api.get(`/task_values?monitoring_id=${id}`);
  return response.data;
};

export const getIntervals = async (id) => {
  const response = await api.get(`/get_interval_data?monitoring_id=${id}`);
  return response.data;
};

export const getDashboard = async (id) => {
  const response = await api.get("/dashboard");
  return response.data;
};

export const logoutAPI = async () => {
  const response = await api.post("/auth/logout", { data: "" });
  return response.data;
};

export const postCustomerTicket = async (data) => {
  // Create a new FormData instance
  const formData = new FormData();

  // Append text fields
  formData.append("subject", data.subject);
  formData.append("description", data.description);

  // Append each file from attachments array
  data.attachments.forEach((file, index) => {
    formData.append(`attachment`, file);
  });

  // Make the post request with formData
  const response = await api.post("/tickets/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set content type as multipart/form-data
    },
  });

  return response.data;
};

export const getCustomerTicket = async () => {
  const response = await api.get("/tickets/customer");
  return response.data;
};

export const getACustomerTicket = async (id) => {
  const response = await api.get(`/tickets/customer?ticket_id=${id}`);
  return response.data;
};

export const editCustomerTicket = async (data, id) => {
  // Create a new FormData instance
  const formData = new FormData();

  // Append text fields
  formData.append("subject", data.subject);
  formData.append("description", data.description);

  // Append each file from attachments array
  data.attachments.forEach((file, index) => {
    console.log("File", file);

    formData.append(`attachment`, file);
  });

  // Make the patch request with formData
  const response = await api.patch(`/tickets/update/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set content type as multipart/form-data
    },
  });
  return response.data;
};

export const deleteCustomerTicket = async (data) => {
  const response = await api.delete("/tickets/delete", { data });
  return response.data;
};

export const exportNodes = async (name, type, start_date, end_date) => {
  const params = new URLSearchParams();

  if (name) params.append("name", name);
  if (type) params.append("type", type);
  if (start_date) params.append("start_date", start_date);
  if (end_date) params.append("end_date", end_date);

  const queryString = params.toString();

  const response = await api.get(`/nodes/export?${queryString}`);
  return response.data;
};

export const exportMonitor = async (name, start_date, end_date) => {
  const params = new URLSearchParams();

  if (name) params.append("name", name);
  if (start_date) params.append("start_date", start_date);
  if (end_date) params.append("end_date", end_date);

  const queryString = params.toString();

  const response = await api.get(`/monitoring_tasks/export?${queryString}`);
  return response.data;
};

export const passwordConfirm = async (data) => {
  const response = await api.post("/auth/reset_password_with_code", data);
  return response.data;
};

export const adminTicketupdate = async (data) => {
  const response = await api.post("/admin/tickets/update", data);
  return response.data;
};

export const dashboardWidgetGet = async () => {
  const response = await api.get("/dashboard/widgets");
  return response.data;
};

export const dashboardWidgetEdit = async (data) => {
  const response = await api.patch("/dashboard/widgets/index", data);
  return response.data;
};

export const postAdminTicket = async (data) => {
  // Create a new FormData instance
  const formData = new FormData();

  // Append text fields
  formData.append("subject", data.subject);
  formData.append("description", data.description);
  formData.append("customer_email", data.customer_email);
  formData.append("status", data.status);
  formData.append("priority", data.priority);

  // Append each file from attachments array
  data.attachments.forEach((file, index) => {
    formData.append(`attachment`, file);
  });

  // Make the post request with formData
  const response = await api.post("/admin/tickets/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set content type as multipart/form-data
    },
  });

  return response.data;
};

export const ticketCommentsById = async (id) => {
  const response = await api.get(`/tickets/${id}/comments`);
  return response.data;
};

export const postComment = async (
  ticketId,
  message,
  attachments = [],
  parentCommentId = null,
  isInternal
) => {
  // Create a new FormData instance
  const formData = new FormData();

  // Append message and parent_comment_id
  formData.append("message", message);
  formData.append("parent_comment_id", parentCommentId);
  formData.append("is_internal", isInternal);

  // Append each file from attachments array
  attachments.forEach((file) => {
    formData.append("attachments", file);
  });

  // Make the post request with FormData
  const response = await api.post(`/tickets/${ticketId}/comments`, formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set content type as multipart/form-data
    },
  });

  return response.data; // Assuming the API returns the new comment object
};

export const adminNotificationList = async (id) => {
  const response = await api.get("/notifications/admin");
  return response.data;
};

export const adminNotificationReadAll = async (id) => {
  const response = await api.post("/notifications/admin/mark-read");
  return response.data;
};

export const customerChangeLogo = async (formData) => {
  // Make the post request with FormData
  const response = await api.post(`/customers/logo`, formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set content type as multipart/form-data
    },
  });

  return response.data; // Assuming the API returns the new comment object
};
